<template>
  <Logo/>
  <div class="ml-embedded" data-form="5bBqy7"></div>
  <div class="social-links-container">
    <!-- X Logo section -->
    <div class="social-link">
      <div class="logo-container">
        <img 
          src="@/assets/XLogo.svg" 
          alt="X (Twitter) Logo"
        />
      </div>
      <a href="https://x.com/apexfalcongames">Follow us on X!</a>
    </div>
    
    <!-- Bluesky Logo section -->
    <div class="social-link">
      <div class="logo-container">
        <img 
          src="@/assets/BlueSkyLogo.svg" 
          alt="Bluesky Logo"
        />
      </div>
      <a href="https://bsky.app/profile/apexfalcongames.bsky.social">
        Follow us on Bluesky!
      </a>
    </div>
  </div>
</template>

<style scoped>
/* Container for all social links */
.social-links-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;  /* Space between social links */
  margin-top: 16px;
}

/* Individual social link wrapper */
.social-link {
  display: flex;
  align-items: center;
  gap: 8px;  /* Space between logo and text */
}

/* Logo container with fixed dimensions */
.logo-container {
  width: 24px;  /* Fixed width for logos */
  height: 24px;  /* Fixed height for logos */
  flex-shrink: 0;  /* Prevents logo from shrinking */
}

/* Logo image styling */
.logo-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;  /* Maintains aspect ratio */
}

/* Link styling */
.social-link a {
  text-decoration: none;
  color: inherit;
}

.social-link a:hover {
  text-decoration: underline;
}
</style>

<script>
import Logo from './components/Logo.vue'


export default {
  name: 'App',
  components: {
    Logo
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
