<template>
  <div class="logo">
    <img src="../assets/Logo.png"/>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<style scoped>
.logo {
  margin: auto;
  width: 25%;
  height: 25%;
}

.logo img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}
</style>
